import React, { useState, useEffect } from "react";
import InputForm from "comp/FormComponents/inputForm";
import SelectForm from "comp/FormComponents/selectForm";
import DateSelection from "comp/FormComponents/datePicker";
import { Icon } from "@iconify/react";
import { classOptions } from "./messageConfig";
import { dateFormatter } from "utils/helperFunctions";
import { useRouter } from "next/router";
import { encodeData } from "utils/encodeDecode";
import { XceltripButton } from "src/components/Button";
import useComponentVisible from "src/customHooks/useComponentVisible";
import { ClassOfTravelDropdown } from "./searchComponents/classOfTravelDropdown";
import { MobileFlightFromSearch } from "./searchComponents/mobileFlightFromSearch";
import { MobileFlightToSearch } from "./searchComponents/mobileFlightToSearch";
import { Dropdown } from "flowbite-react";
import { FullscreenOnMobile } from "src/components/FullscreenOnMobile";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";
import useIsSmallDevice from "src/customHooks/useIsSmallDevice";

interface Props {
  data: any;
  isHomePage?: any;
}

const FlightComponents = (props: any) => {
  const {
    data,
    isLoading,
    flightList,
    handleChange,
    handleDateChange,
    handleshowFlightList,
    isHomePage = false,
    toggleData,
  } = props;

  const [errors, seterrors] = React.useState<any>({});
  const [alterCalendar, setAlterCalendar] = useState(false);
  const [isMobileGuestsModalShown, setisMobileGuestsModalShown] =
    React.useState(false);
  // const [isReallyMobile, setisReallyMobile] = useState(false);
  // useEffect(() => {
  //   if (isMobile) setisReallyMobile(true);
  //   else setisReallyMobile(false);
  // }, [isMobile]);
  const searchData = data;
  const router = useRouter();
  const validation = () => {
    let err: any = {};
    if (!searchData.flight_from_id) err.from = "Please fill the form";
    if (!searchData.flight_to_id) err.to = "Please fill the form";
    return err;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    console.log(searchData,'sssssssssssssssearchasf')
    const error: any = validation();
    seterrors(error);
    if (Object.keys(error).length === 0) {
      let sendData: any = {
        adults: searchData.adults,
        children: searchData.children,
        infants: searchData.infants,
        from: searchData.from,
        to: searchData.to,
        onward: dateFormatter(searchData.onward, "y-m-d"),
        "return-date":
          searchData.oneWay === false
            ? dateFormatter(searchData["return-date"], "y-m-d")
            : "",
        cabin_type: searchData.cabin_type,
        origin_city: searchData?.fromData?.city || searchData.origin_city,
        origin_ita_code:
          searchData?.fromData?.ita_code || searchData.origin_ita_code,
        origin_country_code:
          searchData?.fromData?.country_code || searchData.origin_country_code,
        origin_airport:
          searchData?.fromData?.airPortName || searchData.origin_airport,
        destination_city:
          searchData?.toData?.city || searchData.destination_city,
        destination_ita_code:
          searchData?.toData?.ita_code || searchData.destination_ita_code,
        destination_country_code:
          searchData?.toData?.country_code ||
          searchData.destination_country_code,
        destination_airport:
          searchData?.toData?.airPortName || searchData.destination_airport,
        type: searchData.oneWay === false ? "round_trip" : "one_way",
      };
      var encodedString = encodeData(sendData);
      router.push({
        pathname: "/flight-results/[id]",
        query: { id: encodedString },
      });
    }
  };
  const handleFlightChange = (event: any) => {
    delete errors[event.target.name];
    handleChange(event);
  };
  const getData = (key: string) => {
    // return data.guest.reduce(function (acc: any, obj: any) {
    //   return acc + obj[key];
    // }, 0);
    return 1;
  };
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [isMobileFromSearchModalShown, setisMobileFromSearchModalShown] =
    React.useState(false);
  const [isMobileToSearchModalShown, setisMobileToSearchModalShown] =
    React.useState(false);
  // console.log(searchData.flight_from_id,'test');

  const isSmallDevice = useIsSmallDevice(() => setIsComponentVisible(false));

  const [showFilter, setshowFilter] = React.useState(false);

  const passengerComponent = (
    <div
      className={` ${!isHomePage ? " wrapper-inputGuest" : ""}
  basis-1/5 relative flex flex-col`}
    >
      <div className="dropdown dropdown-hover relative">
        <div
          className={` lg:absolute lg:mt-10 invisible z-[1]
      `}
        >
          <div
            className={` dropdown-content     text-primary-content bg-white dark:bg-dark-bg-primary rounded-xl shadow-m1 p-4 w-auto lg:w-96
            lg:h-auto !h-full visible 
  before:absolute before:left-[20px] before:border-b-[8px]
        before:border-l-[8px] before:border-white before:top-[-8px]
        before:border-r-[8px] before:border-r-transparent
        before:border-l-transparent
h-auto visible bg-white`}
          >
            <div className="dark:text-dark-text-primary">
              <div className="flex items-center justify-between px-5 py-5">
                <div>Adult</div>
                <div className="flex gap-2.5">
                  <button
                    className="btn btn--minus btn--minus--children "
                    type="button"
                    name="button"
                    disabled={data.adults === 1}
                    onClick={() =>
                      handleFlightChange({
                        target: {
                          name: "adults",
                          value: data.adults - 1,
                        },
                      })
                    }
                  >
                    -
                  </button>
                  {data.adults}{" "}
                  <button
                    className="btn btn--plus btn--minus--children "
                    disabled={
                      data.adults === data.totalPassengers - data.children
                    }
                    onClick={() =>
                      handleFlightChange({
                        target: {
                          name: "adults",
                          value: data.adults + 1,
                        },
                      })
                    }
                  >
                    +
                  </button>
                </div>
              </div>

              <div className="border-y flex items-center justify-between px-5 py-5">
                <div className="flex flex-col">
                  <span>Children</span>
                  <span className="text-xs font-normal">2-11 years old</span>
                </div>
                <div className="flex gap-2.5">
                  <button
                    className="btn btn--plus btn--minus--children"
                    disabled={data.children === 0}
                    onClick={() =>
                      handleFlightChange({
                        target: {
                          name: "children",
                          value: data.children - 1,
                        },
                      })
                    }
                  >
                    -
                  </button>{" "}
                  {data.children}{" "}
                  <button
                    className="btn btn--plus btn--minus--children"
                    disabled={
                      data.children === data.totalPassengers - data.adults
                    }
                    onClick={() =>
                      handleFlightChange({
                        target: {
                          name: "children",
                          value: data.children + 1,
                        },
                      })
                    }
                  >
                    +
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-between px-5 py-5">
                <div className="flex flex-col">
                  <span>Infant</span>
                  <span className="text-xs font-normal">below 2 years old</span>
                </div>

                <div className="flex gap-2.5">
                  <button
                    className="btn btn--plus btn--minus--children"
                    disabled={data.infants === 0}
                    onClick={() =>
                      handleFlightChange({
                        target: {
                          name: "infants",
                          value: data.infants - 1,
                        },
                      })
                    }
                  >
                    -
                  </button>{" "}
                  {data.infants}{" "}
                  <button
                    className="btn btn--plus btn--minus--children"
                    disabled={data.infants === data.adults}
                    onClick={() =>
                      handleFlightChange({
                        target: {
                          name: "infants",
                          value: data.infants + 1,
                        },
                      })
                    }
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
        tabIndex={0}
        className={`absolute overflow-hidden dropdown-content shadow bg-primary bg-white text-primary-content rounded w-80 lg:w-96 p-4 lg:p-[30px] lg:mt-7  transition-all
          ${isComponentVisible ? "h-auto" : "!h-0 !p-0"}
        
        `}
        ref={ref}
        // style={{ minWidth: "180%" }}
      >
        xx
      </div> */}
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <MobileFlightFromSearch
        isShown={isMobileFromSearchModalShown}
        setisShown={setisMobileFromSearchModalShown}
        errors={errors}
        data={data}
        handleFlightChange={handleFlightChange}
        isLoading={isLoading}
        flightList={flightList}
        handleshowFlightList={handleshowFlightList}
      />
      <MobileFlightToSearch
        isShown={isMobileToSearchModalShown}
        setisShown={setisMobileToSearchModalShown}
        errors={errors}
        data={data}
        handleFlightChange={handleFlightChange}
        isLoading={isLoading}
        flightList={flightList}
        handleshowFlightList={handleshowFlightList}
      />

      <div
        className={`${
          !isHomePage
            ? "gradientMain py-2 block lg:hidden text-white"
            : "hidden"
        }`}
        onClick={() => setshowFilter(!showFilter)}
      >
        <div className="py-2 px-2  mb-2 sm:mb-2 bg-[hsla(0,0%,100%,.1)] rounded w-full relative">
          <div className="text-base capitalize">
            <span>{searchData?.fromData?.city || searchData.origin_city}</span>{" "}
            to {""}
            <span>
              {searchData?.toData?.city || searchData.destination_city}
            </span>
          </div>
          <div className="text-xs flex text-[#B589FF]">
            <div>
              <span>
                {dayjs(new Date(searchData.onward)).format("DD MMM YYYY")}
              </span>
              <span className="px-1">-</span>
              {searchData["return-date"] && (
                <span>
                  {dayjs(new Date(searchData["return-date"])).format(
                    "DD MMM YYYY"
                  )}
                </span>
              )}
            </div>
            {/* <span>,</span> */}
            <div className="pl-1">
              {/* <span>1 Guest </span> <span>Adults</span> */}
              <span>{data.adults} Adult </span>
              <span>{data.children} Children </span>
              <span> {data.infants} Infant</span>
            </div>
          </div>

          <div className="absolute top-1/2 right-4 translate-y-[-50%]">
            <Icon icon="ic:twotone-mode-edit-outline" className="w-6 h-6" />
          </div>
        </div>
      </div>

      <div
        className={`${
          !isHomePage
            ? `modify-search bg-transparent  lg:visible lg:opacity-[1] lg:h-auto ${
                showFilter
                  ? "visible h-auto opacity-1 "
                  : "invisible h-0 opacity-0"
              } !p-0 `
            : "bg-white dark:bg-dark-bg-primary rounded-b-xl  lg:rounded-xl lg:rounded-tl-none shadow-m1 px-4 py-2.5 pb-4 "
        } `}
      >
        {/* dropdowntags */}
        <div className="flex items-center gap-2 pb-2.5">
          <div className={`form-control py-1 font-medium flight-dropdown `}>
            {/* <p className=" mb-1 text-xs font-medium">{label}</p> */}
            <Icon icon="mdi:airplane" className=" w-4 h-4 mr-2" />
            <Dropdown
              label={searchData.oneWay === false ? "round trip" : "one way"}
              inline={true}
            >
              <Dropdown.Item
                onClick={() =>
                  handleChange({
                    target: {
                      name: "oneWay",
                      value: true,
                    },
                  })
                }
              >
                One Way
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() =>
                  handleChange({
                    target: {
                      name: "oneWay",
                      value: false,
                    },
                  })
                }
              >
                Round Trip
              </Dropdown.Item>
            </Dropdown>
          </div>

          <ClassOfTravelDropdown
            name="cabin_type"
            array={classOptions}
            placeholder="Select One"
            label={"CLASS OF TRAVEL"}
            handleChange={handleFlightChange}
            value={data.cabin_type}
          />
        </div>

        <div
          className={`${!isHomePage ? "!gap-2" : " gap-2"} 
          flex flex-col lg:flex-row justify-between `}
        >
          <div className="sm:flex-row relative flex flex-col flex-wrap flex-1 gap-2">
            {/* from */}
            <div
              className={`${
                !isHomePage
                  ? "wrapper-inputLocation  "
                  : "flex-1 bg-[#f8f8f8] border border-[#f8f8f8] dark:bg-dark-bg-secondary dark:border-transparent    lg:[&:has(input:focus,input:focus-within)]:border-newPrimary-100 dark:[&:has(input:focus,input:focus-within)]:border-dark-text-secondary"
              }
          relative lg:w-52 rounded-lg px-3 py-2 pb-0  bg-[#f0f3f5] `}
            >
              <div
                onClick={(e) => {
                  if (isSmallDevice) {
                    setisMobileFromSearchModalShown(true);
                  }
                }}
              >
                <InputForm
                  name="from"
                  label="From"
                  errors={errors}
                  showErrorLable={false}
                  value={data.from || ""}
                  handleChange={handleFlightChange}
                  placeholder={"Search by city/airport"}
                  className={
                    !isHomePage
                      ? " "
                      : " border-none !shadow-none pl-6  pt-7 focus:!ring-0 focus:!border-0 focus:!outline-none  font-medium !text-base !bg-transparent "
                  }
                  labelClass={
                    !isHomePage ? " " : " absolute dark:text-dark-text-primary "
                  }
                />
              </div>
              {isLoading && data.showFromList && (
                <Icon
                  icon="eos-icons:loading"
                  width="30"
                  height="30"
                  className="absolute w-6 h-6 right-2 top-[25px] "
                />
              )}
              {flightList.length > 0 && data.showFromList && (
                <div
                  className={`  showResult absolute shadow-m1  
                ml-[-16px] lg:mt-8 list-none bg-white p-4 pr-2    cursor-default rounded-xl  before:absolute before:left-[20px] before:border-b-[8px]
             before:border-l-[8px] before:border-white before:top-[-8px]
             before:border-r-[8px] before:border-r-transparent
             before:border-l-transparent z-[1]`}
                >
                  <ul className=" cursor-pointer  mb-2 overflow-hidden overflow-y-auto max-h-[40vmin] min-w-[30vmin]  w-full h-full last:mb-0">
                    {flightList.map((eachList: any, eachIndex: any) => (
                      <li
                        className="last:mb-0 mb-4 text-base"
                        onClick={(e) =>
                          handleFlightChange({
                            target: {
                              name: "flight_from_id",
                              value: eachList.value,
                              text: eachList.text,
                              data: eachList,
                            },
                          })
                        }
                        key={eachIndex + 1 + ""}
                      >
                        <Icon
                          icon="mdi:airplane-marker"
                          className="w-6 h-6 mr-2"
                        />
                        <div dangerouslySetInnerHTML={{__html: eachList?.text || eachList?.value}}></div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* switch */}
            {(data.to || data.from) && (
              <div
                onClick={toggleData}
                className={`bg-white flex justify-center items-center shadow-m1 rounded-full w-10 h-10 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2  z-[1]`}
              >
                <span className="cursor-pointer">
                  <Icon
                    icon={`ion:swap-horizontal`}
                    className={`text-newText-100 sm:rotate-0 rotate-90 `}
                  />
                </span>
              </div>
            )}

            {/* to */}
            <div
              className={`${
                !isHomePage
                  ? "wrapper-inputLocation "
                  : "flex-1 bg-[#f8f8f8] border border-[#f8f8f8] dark:bg-dark-bg-secondary dark:border-transparent    lg:[&:has(input:focus,input:focus-within)]:border-newPrimary-100 dark:[&:has(input:focus,input:focus-within)]:border-dark-text-secondary"
              }
          relative lg:w-52  rounded-lg px-3 py-2 pb-0  bg-[#f0f3f5] `}
            >
              <div
                onClick={(e) => {
                  if (isSmallDevice) {
                    setisMobileToSearchModalShown(true);
                  }
                }}
              >
                <InputForm
                  name="to"
                  label="To"
                  errors={errors}
                  value={data.to || ""}
                  showErrorLable={false}
                  handleChange={handleFlightChange}
                  placeholder={"Search by city/airport"}
                  className={
                    !isHomePage
                      ? " "
                      : " border-none !shadow-none pl-6  pt-7 focus:!ring-0 focus:!border-0 focus:!outline-none   !bg-transparent font-medium !text-base "
                  }
                  labelClass={
                    !isHomePage ? " " : " absolute dark:text-dark-text-primary "
                  }
                />
              </div>
              {isLoading && data.showToList && (
                <Icon
                  icon="eos-icons:loading"
                  width="30"
                  height="30"
                  className="absolute w-6 h-6 right-2 top-[25px] "
                />
              )}
              {flightList.length > 0 && data.showToList && (
                <div
                  className={` showResult absolute shadow-m1  
                ml-[-16px] lg:mt-8 list-none bg-white p-4 pr-2    cursor-default rounded-xl  before:absolute before:left-[20px] before:border-b-[8px]
             before:border-l-[8px] before:border-white before:top-[-8px]
             before:border-r-[8px] before:border-r-transparent
             before:border-l-transparent z-[1]  `}
                >
                  <ul className=" cursor-pointer  mb-2 overflow-hidden overflow-y-scroll max-h-[40vmin] min-w-[30vmin]  w-full h-full last:mb-0">
                    {flightList.map((eachList: any, eachIndex: any) => (
                      <li
                        className="last:mb-0 mb-4 text-base"
                        onClick={(e) =>
                          handleFlightChange({
                            target: {
                              name: "flight_to_id",
                              value: eachList.value,
                              text: eachList.text,
                              data: eachList,
                            },
                          })
                        }
                        key={eachIndex + 1 + ""}
                      >
                        <Icon
                          icon="mdi:airplane-marker"
                          className="w-6 h-6 mr-2"
                        />
                        <div dangerouslySetInnerHTML={{__html: eachList?.text || eachList?.value}}></div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/* depart */}
          <div
            className={` ${
              !isHomePage ? "!py-0 !gap-2 uppercase font-medium " : "  "
            }
          relative flex justify-between gap-2 ${
            data.oneWay ? " " : " lg:grid lg:grid-cols-2 lg:col-span-2 lg:gap-2"
          } `}
          >
            {data.oneWay && (
              <div
                className={` ${
                  !isHomePage ? "wrapper-inputDate-1 !text-sm" : "FDSingle"
                } FD-1 relative w-full lg:min-w-[140px] rounded-lg px-3 py-2 pb-0  bg-[#f8f8f8] border border-[#f8f8f8] dark:bg-dark-bg-secondary dark:border-transparent    lg:[&:has(input:focus,input:focus-within)]:border-newPrimary-100 dark:[&:has(input:focus,input:focus-within)]:border-dark-text-secondary  `}
              >
                <DateSelection
                  data={data}
                  name={"onward"}
                  errors={errors}
                  label="Depart on"
                  selectStart={true}
                  // endDate="return-date"
                  alterOtherDate="return-date"
                  placeholder="Select Date"
                  handleDateChange={handleDateChange}
                  isRange={false}
                  selectsOne={true}
                  selectEnd={true}
                  category={"Flight"}
                  daterightMobile={`dark:text-dark-text-primary`}
                />
              </div>
            )}
            {!data.oneWay && (
              <>
                <div
                  className={`${
                    !isHomePage ? "wrapper-inputDate-1 " : " FDReturn-1 "
                  }
                relative  w-full lg:min-w-[140px] rounded-lg px-3 py-2 
                bg-[#f8f8f8] border border-[#f8f8f8] dark:bg-dark-bg-secondary dark:border-transparent    lg:[&:has(input:focus,input:focus-within)]:border-newPrimary-100 dark:[&:has(input:focus,input:focus-within)]:border-dark-text-secondary
                `}
                >
                  <DateSelection
                    data={data}
                    name={"onward"}
                    errors={errors}
                    label="Depart on"
                    selectStart={true}
                    endDate="return-date"
                    alterOtherDate="return-date"
                    placeholder="Select Date"
                    handleDateChange={handleDateChange}
                    isRange={true}
                    selectsOne={false}
                    category={"Flight"}
                    selectEnd={false}
                    alterCalendar={false}
                    isStart={true}
                    daterightMobile={`dark:text-dark-text-primary`}
                    setAlterCalendar={setAlterCalendar}
                  />
                </div>
                <div
                  className={`${
                    !isHomePage ? "wrapper-inputDate-1" : "FDReturn-1 "
                  }
                relative w-full lg:min-w-[140px] rounded-lg px-3 py-2   bg-[#f8f8f8] border border-[#f8f8f8] dark:bg-dark-bg-secondary dark:border-transparent    lg:[&:has(input:focus,input:focus-within)]:border-newPrimary-100 dark:[&:has(input:focus,input:focus-within)]:border-dark-text-secondary `}
                >
                  <DateSelection
                    selectEnd
                    data={data}
                    name={"return-date"}
                    errors={errors}
                    startDate={"onward"}
                    endDate={"return-date"}
                    label="Return On"
                    minDate={data.onward}
                    placeholder="Select Date"
                    handleDateChange={handleDateChange}
                    isRange={true}
                    selectsOne={true}
                    isStart={false}
                    category={"Flight"}
                    alterCalendar={alterCalendar}
                    setAlterCalendar={setAlterCalendar}
                    daterightMobile={` dark:text-dark-text-primary`}
                    // className="absolute right-0"
                  />
                </div>
              </>
            )}
          </div>

          {/* passenger */}
          {!!isSmallDevice && (
            <>
              <div className="col-span-2 wrapper-inputGuest lg:min-w-[140px] rounded-lg px-3 py-2   bg-[#f8f8f8] border border-[#f8f8f8] dark:bg-dark-bg-secondary dark:border-transparent    lg:[&:has(input:focus,input:focus-within)]:border-newPrimary-100 dark:[&:has(input:focus,input:focus-within)]:border-dark-text-secondary ">
                <label
                  className={`

                  ${
                    !isHomePage
                      ? "uppercase font-bold tracking-wide"
                      : "dark:text-dark-text-primary"
                  }
                 
                  text-sm  block pb-1.5  `}
                  // onClick={() => {
                  //   setisMobileGuestsModalShown(true);
                  //   setIsComponentVisible(!isComponentVisible);
                  // }}
                >
                  Passengers
                </label>

                <div className="dropdown dropdown-hover relative">
                  <label
                    className="btn btn-ghost  !px-0 !h-0 !min-h-0 !border-none !bg-transparent  !capitalize cursor-pointer font-semibold  dark:text-dark-text-primary"
                    tabIndex={0}
                    onClick={() => {
                      setisMobileGuestsModalShown(true);
                      setIsComponentVisible(!isComponentVisible);
                    }}
                  >
                    {data.adults} Adult | {data.children} Children |{" "}
                    {data.infants} Infant
                  </label>
                </div>
              </div>
              <FullscreenOnMobile
                isShown={isMobileGuestsModalShown}
                setisShown={setisMobileGuestsModalShown}
                header={
                  <div className="flex">
                    <strong className="m-auto">Select Guests</strong>
                    <button
                      onClick={() => {
                        setisMobileGuestsModalShown(false);
                      }}
                    >
                      <Icon icon="akar-icons:cross" />
                    </button>
                  </div>
                }
                content={passengerComponent}
              />
            </>
          )}

          {!isSmallDevice && (
            <div
              className={` ${!isHomePage ? " wrapper-inputGuest" : ""}
                relative flex flex-col rounded-lg px-3 py-2   bg-[#f8f8f8] border border-[#f8f8f8] dark:bg-dark-bg-secondary dark:border-transparent    lg:[&:has(input:focus,input:focus-within)]:border-newPrimary-100 dark:[&:has(input:focus,input:focus-within)]:border-dark-text-secondary`}
            >
              <label
                className={`label !p-0 !pb-1.5 text-sm  ${
                  !isHomePage
                    ? "uppercase font-medium "
                    : "dark:text-dark-text-primary "
                } `}
              >
                Passengers
              </label>
              <div className="dropdown dropdown-hover relative">
                <Icon
                  icon={`solar:users-group-rounded-broken`}
                  className={` ${
                    router.pathname == "/" ? " text-gray-500" : "text-white"
                  } mr-2 mb-[3px] `}
                />
                <label
                  className="btn btn-ghost !font-semibold !px-0 !h-0 !min-h-0 !border-none !bg-transparent  !capitalize cursor-pointer dark:text-dark-text-primary  "
                  tabIndex={0}
                  onClick={() => setIsComponentVisible(!isComponentVisible)}
                >
                  {data.adults} Adult | {data.children} Children |{" "}
                  {data.infants} Infant
                </label>
                {/* {passengerComponent} */}
                <div
                  className={`absolute lg:mt-10 invisible z-[1]
          `}
                >
                  <div
                    className={` dropdown-content  transition-all  text-primary-content bg-white dark:bg-dark-bg-tertiary rounded-xl shadow-m1 p-4 w-auto lg:w-96
              
              ${
                isComponentVisible
                  ? "h-auto visible bg-white"
                  : " !p-0 invisible bg-white"
              }
      before:absolute before:left-[20px] before:border-b-[8px]
            before:border-l-[8px] before:border-white before:dark:border-b-dark-bg-tertiary  before:top-[-8px]
            before:border-r-[8px] before:border-r-transparent
            before:border-l-transparent
    h-auto visible bg-white`}
                  >
                    <div className="dark:text-dark-text-primary" ref={ref}>
                      <div className="flex items-center justify-between px-5 py-5">
                        <div>Adult</div>
                        <div className="flex gap-2.5">
                          <button
                            className="btn btn--minus btn--minus--children "
                            type="button"
                            name="button"
                            disabled={data.adults === 1}
                            onClick={() =>
                              handleFlightChange({
                                target: {
                                  name: "adults",
                                  value: data.adults - 1,
                                },
                              })
                            }
                          >
                            -
                          </button>
                          {data.adults}{" "}
                          <button
                            className="btn btn--plus btn--minus--children "
                            disabled={
                              data.adults ===
                              data.totalPassengers - data.children
                            }
                            onClick={() =>
                              handleFlightChange({
                                target: {
                                  name: "adults",
                                  value: data.adults + 1,
                                },
                              })
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>

                      <div className="border-y flex items-center justify-between px-5 py-5">
                        <div className="flex flex-col">
                          <span>Children</span>
                          <span className="text-xs font-normal">
                            2-11 years old
                          </span>
                        </div>
                        <div className="flex gap-2.5">
                          <button
                            className="btn btn--plus btn--minus--children"
                            disabled={data.children === 0}
                            onClick={() =>
                              handleFlightChange({
                                target: {
                                  name: "children",
                                  value: data.children - 1,
                                },
                              })
                            }
                          >
                            -
                          </button>{" "}
                          {data.children}{" "}
                          <button
                            className="btn btn--plus btn--minus--children"
                            disabled={
                              data.children ===
                              data.totalPassengers - data.adults
                            }
                            onClick={() =>
                              handleFlightChange({
                                target: {
                                  name: "children",
                                  value: data.children + 1,
                                },
                              })
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>

                      <div className="flex items-center justify-between px-5 py-5">
                        <div className="flex flex-col">
                          <span>Infant</span>
                          <span className="text-xs font-normal">
                            below 2 years old
                          </span>
                        </div>

                        <div className="flex gap-2.5">
                          <button
                            className="btn btn--plus btn--minus--children"
                            disabled={data.infants === 0}
                            onClick={() =>
                              handleFlightChange({
                                target: {
                                  name: "infants",
                                  value: data.infants - 1,
                                },
                              })
                            }
                          >
                            -
                          </button>{" "}
                          {data.infants}{" "}
                          <button
                            className="btn btn--plus btn--minus--children"
                            disabled={data.infants === data.adults}
                            onClick={() =>
                              handleFlightChange({
                                target: {
                                  name: "infants",
                                  value: data.infants + 1,
                                },
                              })
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div
            tabIndex={0}
            className={`absolute overflow-hidden dropdown-content shadow bg-primary bg-white text-primary-content rounded w-80 lg:w-96 p-4 lg:p-[30px] lg:mt-7  transition-all
              ${isComponentVisible ? "h-auto" : "!h-0 !p-0"}
            
            `}
            ref={ref}
            // style={{ minWidth: "180%" }}
          >
            xx
          </div> */}
              </div>
            </div>
          )}
          {/* {passengerComponent} */}
          {/* <div className="basis-1/7 ">
            <ClassOfTravelDropdown
              name="cabin_type"
              array={classOptions}
              placeholder="Select One"
              label={"CLASS OF TRAVEL"}
              handleChange={handleFlightChange}
              value={data.cabin_type}
            />
            <SelectForm
              name="cabin_type"
              array={classOptions}
              placeholder="Select One"
              label={"Class of Travel"}
              handleChange={handleFlightChange}
              value={data.cabin_type}
            />
          </div> */}
          <div
            className={` ${!isHomePage ? "wrapper-Search" : ""}
           `}
            onClick={() => setshowFilter(!showFilter)}
          >
            <XceltripButton.IconButton
              isHomePage
              handleSubmit={handleSubmit}
              icon={<Icon className="w-5 h-5" icon="bx:search" />}
              text="Search"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FlightComponents;
