import { Icon } from "@iconify/react";
import React from "react";
import InputForm from "src/components/FormComponents/inputForm";
import { FullscreenOnMobile } from "src/components/FullscreenOnMobile";

interface MobileFlightFromSearchProps {
  isShown: boolean;
  setisShown: any;
  errors: any;
  data: any;
  handleFlightChange: any;
  flightList: any;
  isLoading: boolean;
  handleshowFlightList: any;
}

export const MobileFlightFromSearch = ({
  isShown,
  setisShown,
  errors,
  data,
  handleFlightChange,
  flightList,
  isLoading,
  handleshowFlightList,
}: MobileFlightFromSearchProps) => {
  return (
    <>
      <FullscreenOnMobile
        className='one'
        isShown={isShown}
        setisShown={setisShown}
        header={
          <>
            <div className="flex">
              <strong className="m-auto">Search From Location</strong>
              <button
                onClick={() => {
                  setisShown(false);
                  handleshowFlightList(false, "showFromFlightList");
                }}
              >
                <Icon icon="akar-icons:cross" />
              </button>
            </div>
            <div className="relative mt-5">
              <InputForm
                name="from"
                label="From"
                errors={errors}
                showErrorLable={false}
                value={data.from || ""}
                handleChange={handleFlightChange}
                placeholder={"Search by city/airport"}
                className={`pl-7`}
                iconClass={`top-[2px]  left-2`}
              />

              {isLoading && data.showFromList && (
                <Icon
                  icon="eos-icons:loading"
                  className="right-2 top-7 absolute"
                  width="30"
                  height="30"
                />
              )}
            </div>
          </>
        }
        content={
          <div className="my-5 text-center">
            {flightList.length > 0 && data.showFromList && (
              <ul className="rounded-box list-none cursor-pointer">
                {flightList.map((eachList: any, eachIndex: any) => (
                  <li
                    className="flex items-center px-5 mb-5 text-base"
                    onClick={(e) => {
                      handleFlightChange({
                        target: {
                          name: "flight_from_id",
                          value: eachList.value,
                          text: eachList.text,
                          data: eachList,
                        },
                      });
                      setisShown(false);
                    }}
                    key={eachIndex + 1 + "from"}
                  >
                    <span>
                      <Icon icon="mdi:flight" className="pr-2.5 w-8 h-8" />
                    </span>
                    <div dangerouslySetInnerHTML={{ __html: eachList?.text || eachList?.value }}></div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        }
      />
    </>
  );
};
