import React, { useEffect, useState } from "react";
import AlertMessage from "comp/Alert";
import { Dropdown } from "flowbite-react";
import { Icon } from "@iconify/react";

interface Props {
  label: string;
  placeholder?: string;
  name: string;
  value: any;
  handleChange: any;
  array: any;
}
export const ClassOfTravelDropdown = (props: Props) => {
  const { label, array, name, value, placeholder, handleChange } = props;
  const [selectedClass, setSelectedClass] = useState("Select one");
  useEffect(() => {
    let selClass = array.filter((item: any) => item.value == value);
    setSelectedClass(selClass[0].text);
  }, [value, array]);
  return (
    <div className="form-control py-1 font-semibold flight-dropdown">
      {/* <p className="  text-xs font-semibold mb-1 ">{label}</p> */}
      <Icon
        icon="material-symbols:airline-seat-recline-extra-rounded"
        className="w-4 h-4 mr-2 "
      />
      <Dropdown label={selectedClass} inline={true}>
        {/* <Dropdown.Header>
          <span className="block text-sm !uppercase font-normal">
            {placeholder || "Select one"}
          </span>
        </Dropdown.Header> */}
        {array.map((eachLabel: any, index: number) => (
          <Dropdown.Item
            key={index + 1 + ""}
            onClick={() =>
              handleChange({
                target: {
                  name: name,
                  value: eachLabel.value,
                },
              })
            }
          >
            {eachLabel.text}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
};
